// extracted by mini-css-extract-plugin
export var consultation = "contact-form-module--consultation--2YCbW";
export var form = "contact-form-module--form--xcDMi";
export var formWrapper = "contact-form-module--form-wrapper--1faS-";
export var button = "contact-form-module--button--3BG3c";
export var nameWrapper = "contact-form-module--name-wrapper--fmLr6";
export var emailWrapper = "contact-form-module--email-wrapper--1J_4T";
export var phoneWrapper = "contact-form-module--phone-wrapper--3pZrS";
export var needWrapper = "contact-form-module--need-wrapper--4otCp";
export var descriptionWrapper = "contact-form-module--description-wrapper--3AUXt";
export var intlTelInput = "contact-form-module--intl-tel-input--2LGrv";
export var wrapper = "contact-form-module--wrapper--1pQP_";