import React from "react";
import { Helmet } from "react-helmet";
import ContactForm from "components/index/contact-form";
import Footer from "components/index/footer";
import Header from "components/index/header";
import HowWeHelp from "components/index/how-we-help";
import Tagline from "components/index/tagline";
import WeCanHelp from "components/index/we-can-help";
import WhatIsStuck from "components/index/what-is-stuck";
import "normalize.css";
import {
  contactFormWrapper,
  howWeHelpWrapper,
  innerWrapper,
  pageWrapper,
  whatIsStuckWrapper,
  weCanHelpWrapper,
} from "styles/index.module.scss";

export default function IndexPage() {
  return (
    <>
      <Helmet>
        <title>Reposition</title>
        <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
      </Helmet>
      <div className={pageWrapper}>
        <header>
          <Header />
        </header>
        <section>
          <Tagline />
        </section>
        <main className={innerWrapper}>
          <section className={whatIsStuckWrapper}>
            <WhatIsStuck />
          </section>
          <section className={weCanHelpWrapper}>
            <WeCanHelp />
          </section>
          <section className={howWeHelpWrapper}>
            <HowWeHelp />
          </section>
        </main>
        <section className={contactFormWrapper}>
          <ContactForm />
        </section>
        <footer>
          <Footer />
        </footer>
      </div>
    </>
  );
}
