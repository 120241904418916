import React from "react";
import * as styles from "styles/components/index/tagline.module.scss";

export default function Tagline() {
  return (
    <div className={styles.wrapper}>
      <p>
        We are a niche consultancy focused on&nbsp;
        <strong>helping managers reposition for better outcomes.</strong>
      </p>
    </div>
  );
}
