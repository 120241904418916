import React from "react";
import modelImage from "images/model.png";
import * as styles from "styles/components/index/how-we-help.module.scss";

export default function HowWeHelp() {
  return (
    <div className={styles.wrapper}>
      <div>
        <h2>How we help</h2>
        <p className={styles.methodContent}>
          We help leaders apply a ‘Reposition Mindset’ to what is stuck. Whether
          you manage a small team or a global organisation the Reposition
          Mindset helps all managers think in a way that results in informed
          decisions, effective operations and improved outcomes.
        </p>
        <img className={styles.repositionModel} src={modelImage} />
        <span className={styles.copyright}>© Reposition Pty Ltd</span>
      </div>
      <div className={styles.quote}>
        <h2>
          “Everything, including organisations, that <br />
          is left to itself has a tendency to deteriorate.”
        </h2>
        <span>
          <strong>
            ‘The Art of Leadership’
            <br />
            by Max du Pree
          </strong>
        </span>
      </div>
    </div>
  );
}
