import React from "react";
import * as styles from "styles/components/index/we-can-help.module.scss";

export default function WeCanHelp() {
  return (
    <div className={styles.wrapper}>
      <h2>We can help you get unstuck.</h2>
      <div className={styles.boxes}>
        <div className={styles.helpTile1}>
          <h3>Consultation</h3>
          <p>We help individuals rethink and reposition</p>
        </div>
        <div className={styles.helpTile2}>
          <h3>Training</h3>
          <p>We help teams rethink and reposition</p>
        </div>
        <div className={styles.helpTile3}>
          <h3>Intervention</h3>
          <p>We help managers rethink and we reposition on your behalf</p>
        </div>
      </div>
    </div>
  );
}
