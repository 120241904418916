import React from "react";
import careerImage from "images/career.png";
import businessImage from "images/business.png";
import teamImage from "images/team.png";
import * as styles from "styles/components/index/what-is-stuck.module.scss";

export default function WhatIsStuck() {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title} size="xxxlHeading">
        What is stuck?
      </h2>
      <div className={styles.boxes}>
        <div className={styles.introductionBox}>
          <div className={styles.circle}>
            <img src={careerImage} />
          </div>
          <h3 className={styles.subHeading}>Your Career</h3>
        </div>
        <div className={styles.introductionBox}>
          <div className={styles.circle}>
            <img src={teamImage} />
          </div>
          <h3 className={styles.subHeading}>Your Team</h3>
        </div>
        <div className={styles.introductionBox}>
          <div className={styles.circle}>
            <img src={businessImage} />
          </div>
          <h3 className={styles.subHeading}>Your Business</h3>
        </div>
      </div>
    </div>
  );
}
