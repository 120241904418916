import React, { useState } from "react";
import axios from "axios";
import IntlTelInput from "react-intl-tel-input";
import Recaptcha from "react-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-intl-tel-input/dist/main.css";
import "react-toastify/dist/ReactToastify.css";
import * as styles from "styles/components/index/contact-form.module.scss";

export default function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [need, setNeed] = useState("");
  const [description, setDescription] = useState("");

  return (
    <div className={styles.wrapper}>
      <div className={styles.consultation}>
        <h2>
          Request a <strong>FREE</strong> 15 minute phone consultation!
        </h2>
      </div>
      <div className={styles.formWrapper}>
        <div className={styles.form}>
          <div className={styles.nameWrapper}>
            <input
              type="text"
              name="name"
              onChange={onChange(setName)}
              placeholder="Name"
              value={name}
            />
          </div>
          <div className={styles.emailWrapper}>
            <input
              type="email"
              name="email"
              onChange={onChange(setEmail)}
              placeholder="Email"
              value={email}
            />
          </div>
          <div className={styles.phoneWrapper}>
            <IntlTelInput
              placeholder="Phone"
              onPhoneNumberChange={onChangePhone}
              preferredCountries={["au"]}
              value={phone}
            />
          </div>
          <div className={styles.needWrapper}>
            <select onChange={onChange(setNeed)} value={need}>
              <option value="What is stuck?">What is stuck?</option>
              <option value="You">You</option>
              <option value="Your Team">Your Team</option>
              <option value="Your Department">Your Department</option>
              <option value="Your Organisation">Your Organisation</option>
            </select>
          </div>
          <div className={styles.descriptionWrapper}>
            <input
              type="text"
              name="description"
              onChange={onChange(setDescription)}
              placeholder="Describe what is stuck"
              value={description}
            />
          </div>
        </div>
        <div>
          <Recaptcha
            sitekey={`${process.env.RECAPTCHA_SITE_KEY}`}
            size="invisible"
          />
        </div>
        <div>
          <button className={styles.button} onClick={onSubmit} type="submit">
            Request
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );

  function onChange(setFn) {
    return function ({ target }) {
      setFn(target.value);
    };
  }

  function onChangePhone(status, value, countryData, number, id) {
    setCountryCode(countryData.dialCode);
    setPhone(value);
  }

  function onSubmit() {
    const payload = {
      name,
      email,
      phone: `+${countryCode} ${phone}`,
      need,
      description,
    };

    axios
      .post(
        "https://q1ph7calda.execute-api.ap-southeast-2.amazonaws.com/dev/email/send",
        payload
      )
      .then(function () {
        toast.success(
          "Thanks we've received your message and will be in touch!"
        );
        resetForm();
      })
      .catch(function (error) {
        console.log("error", error);
        toast.error(
          "There was an error with sending your message, hold up until I fix it. Thanks for waiting."
        );
      });
  }

  function resetForm() {
    setName("");
    setEmail("");
    setPhone("");
    setNeed("");
    setDescription("");
  }
}
