import React from "react";
import * as styles from "styles/components/index/footer.module.scss";

export default function Footer() {
  return (
    <div>
      <div className={styles.footer}>
        <div className={styles.footerColumn}>
          <p>© Reposition Pty Ltd</p>
          <p>Privacy Policy</p>
        </div>
        <div className={styles.footerColumn}>
          <p>Phone: +61 438 648 678</p>
          <p>Level 3, 16 O'Connell Street, Sydney, NSW, 2000,</p>
          <p>Australia</p>
        </div>
      </div>
    </div>
  );
}
