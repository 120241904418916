import React from "react";
import logo from "images/repo-logo.png";
import * as styles from "styles/components/index/header.module.scss";

export default function Header() {
  return (
    <div className={styles.header}>
      <a className={styles.logoWrapper} href="https://reposition.net.au">
        <img alt="Logo" className={styles.logo} src={logo} />
      </a>
      <h2 className={styles.title} size="xxxlHeading">
        Stuck?
      </h2>
    </div>
  );
}
